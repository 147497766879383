/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ApplicationBlocks, Modal, Button } from '@openbox-app-shared'
import dashboardIcons from '../../../icons'
import InlineLinkFrame from '../../../components/InlineLinkFrame'

import _ from '../../../_'
import { log, error } from '../../../log'

const StyledModal = styled.div`
  .component--modal {
    max-width: 392px;
    padding: 0.25rem 1rem 0rem 1rem;
    padding-top: 2rem !important;
    .component--application-blocks {
      max-width: 256px;
      margin: 0 auto 0 auto;
    }
    .component--h2 {
      height: 1.25rem;
      line-height: 1.25rem;
      text-align: center;
      font-size: 1rem;
    }
    .component--inline-link-frame {
      margin-top: 0.25rem;
      margin-bottom: 1rem;
      > iframe {
        max-height: calc(100vh - 220px);
      }
    }
  }
  .post-thing-qr-stage--2 {
    margin: 0 auto 1rem auto;
  }
`

const TIME_GOODBYE = 2500
const TIME_QR = 5000

function ThisModal ({ user, referenceKey, referenceValue, howToLinkGeneric, linkImage, onDone, onClose, messages = [], showQrImmediate = false }) {
  const [
    message1,
    message2 = 'Customer is ready...',
    message3 = 'Customer has paid!',
    message4 = 'Not paid — try again.',
    message5 = 'Is the Internet working?'
  ] = messages
  const [status, setStatus] = useState('bus-v2-payment-loading')
  const [previewObject, setPreviewObject] = useState()
  const [posThingQrStage, setPosThingQrStage] = useState(showQrImmediate ? 2 : 0)
  useEffect(
    () => {
      let posThingQrStageHandler, goodbyeHandler, free
      log('[PAY_WAIT_ORIGIN]')
      posThingQrStageHandler = !showQrImmediate && setTimeout(
        () => {
          setPosThingQrStage(1)
        },
        TIME_QR
      )
      const onMessage = data => {
        log('[PAY_WAIT_ORIGIN] [->onMessage] 1', { data })
        if (data.status === 'bus-v2-payment-success' && data.busV2PaymentSuccessMatchPaymentId === referenceValue) {
          setStatus('bus-v2-payment-success')
          goodbyeHandler = setTimeout(() => onDone(true), TIME_GOODBYE)
          return
        }
        if (data.status === 'bus-v2-payment-failure' && data.busV2PaymentSuccessMatchPaymentId === referenceValue) {
          setStatus('bus-v2-payment-failure')
          goodbyeHandler = setTimeout(() => onDone(true), TIME_GOODBYE)
          return
        }
        if (data[referenceKey] !== referenceValue) {
          log('[PAY_WAIT_ORIGIN] [->onMessage] 2 returning (referenceValue mismatch)', { referenceKey, referenceValue, data })
          return
        }
        switch (data.status) {
        case 'bus-v2-engaged':
          setStatus('bus-v2-engaged')
          const { sessionId } = data
          howToLinkGeneric && (() => {
            setPreviewObject({
              link: howToLinkGeneric(sessionId),
            })
            // setInterval(
            //   () => {
            //     setPreviewObject({
            //       link: howToLinkGeneric(sessionId) + `&hotRefresh=${window.sticky.uuid()}`
            //     })
            //   },
            //   10 * 1000
            // )
          })()
          break
        case 'bus-v2-payment-success':
          setStatus('bus-v2-payment-success')
          goodbyeHandler = setTimeout(() => onDone(true), TIME_GOODBYE)
          break
        case 'bus-v2-payment-failure':
          setStatus('bus-v2-payment-failure')
          goodbyeHandler = setTimeout(() => onDone(false), TIME_GOODBYE)
          break
        }
      }
      window.sticky.bus.connect(user.lightMode, `${user.publicKey}---generic`, { onMessage })
        .then(({ message, free: sdkFree }) => {
          message({ something: 'somethingElse' })
          free = sdkFree
        })
        .catch(e => {
          error('[App] [onWantsToPay] 3 bus connection failed; ignoring', e)
          setStatus('bus-v2-payment-failure-bus')
        })
      return () => {
        clearTimeout(posThingQrStageHandler)
        clearTimeout(goodbyeHandler)
        free && free()
      }
    },
    []
  )
  return (
    <StyledModal>
      <Modal onClose={onClose}>
        {status === 'bus-v2-payment-loading' && (
          <ApplicationBlocks applicationBlocks={
            [
              {
                id: 'debug',
                config: {
                  font: '#ffffff--center--100%--true',
                  icon: 'loading',
                  backgroundColour: '#6C7A89',
                  what: message1 ? message1 : (posThingQrStage === 2 ? 'Waiting for scan...' : 'Waiting for tap...')
                }
              },
              posThingQrStage === 2 && linkImage && {
                'id': '9545852c-d64a-457a-8ca0-2b2d1173de9b',
                'config': {
                  url: linkImage,
                  'corners': 'Square',
                  'dropShadow': false,
                  'specialEffect': 'None',
                  'lockAspectRatio': true
                }
              }
            ]
              .filter(_ => _)
          } />
        )}
        {status === 'bus-v2-engaged' && (
          <ApplicationBlocks applicationBlocks={[
            {
              id: 'debug',
              config: {
                font: '#ffffff--center--100%--true',
                icon: 'loading',
                backgroundColour: '#6C7A89',
                what: message2
              }
            }
          ]} />
        )}
        {status === 'bus-v2-payment-success' && (
          <ApplicationBlocks applicationBlocks={[
            {
              id: 'debug',
              config: {
                font: '#ffffff--center--100%--true',
                icon: 'check',
                backgroundColour: '#40CF7A',
                what: message3
              }
            }
          ]} />
        )}
        {status === 'bus-v2-payment-failure' && (
          <ApplicationBlocks applicationBlocks={[
            {
              id: 'debug',
              config: {
                font: '#ffffff--center--100%--true',
                icon: 'warning',
                backgroundColour: '#FF3838',
                what: message4
              }
            }
          ]} />
        )}
        {status === 'bus-v2-payment-failure-bus' && (
          <ApplicationBlocks applicationBlocks={[
            {
              id: 'debug',
              config: {
                font: '#ffffff--center--100%--true',
                icon: 'warning',
                backgroundColour: '#FF3838',
                what: message5
              }
            }
          ]} />
        )}
        {posThingQrStage === 1 && (
          <Button
            className='post-thing-qr-stage--2'
            isSecondary
            InlineIcon={dashboardIcons.qrCode}
            onClick={() => {
              setPosThingQrStage(2)
            }}
          >
            Show QR code
          </Button>
        )}
        {previewObject && (
          <>
            <InlineLinkFrame link={previewObject.link} disabled />
          </>
        )}
      </Modal>
    </StyledModal>
  )
}

export default {
  trigger: ({ setState, why, extra }) => {
    setState({ PAY_WAIT_ORIGIN: { why, extra } })
  },
  render: function ThisAction ({ user, state, setState, dispatch }) {
    const { PAY_WAIT_ORIGIN } = state
    if (!PAY_WAIT_ORIGIN) {
      return null
    }
    const { why, extra } = PAY_WAIT_ORIGIN
    log('[PAY_WAIT_ORIGIN] 1', { why, extra })
    const isReallyDone = (isSuccessful) => {
      isSuccessful && dispatch('PAY_WAIT_ORIGIN_GOOD', { why, extra })
      !isSuccessful && dispatch('PAY_WAIT_ORIGIN_BAD', { why, extra })
      setState({
        PAY_WAIT_ORIGIN: undefined
      })
    }
    const { referenceKey, referenceValue, howToLinkGeneric, linkImage, messages, showQrImmediate } = extra
    return (
      <>
        <ThisModal
          user={user}
          referenceKey={referenceKey}
          referenceValue={referenceValue}
          howToLinkGeneric={howToLinkGeneric}
          linkImage={linkImage}
          onDone={isReallyDone}
          onClose={() => {
            dispatch('PAY_WAIT_ORIGIN_CANCEL', { why, extra })
            setState({
              PAY_WAIT_ORIGIN: undefined
            })
          }}
          messages={messages}
          showQrImmediate={showQrImmediate}
        />
      </>
    )
  }
}
